import type { SupportedFooterSocials } from "astro-types/components-interfaces";

import styles from "./FooterSocialIcons.module.css";

import {
  FaInstagram,
  FaLinkedin,
  FaFacebook,
  FaPinterest,
  FaYoutube,
  FaYelp,
  FaTwitter,
  FaGoogle,
  FaTiktok,
} from "react-icons/fa";

interface FooterSocialIconsProps {
  socialLinks: {
    [key in SupportedFooterSocials]?: string;
  };
}

const FooterSocialIcons = ({ socialLinks }: FooterSocialIconsProps) => {
  return (
    <div className={styles.footerSocialIcons}>
      {socialLinks.instagram && (
        <a
          href={socialLinks.instagram}
          target="_blank"
          rel="noreferrer"
          aria-label="visit company Instagram"
          className={styles.iconLink}
        >
          <FaInstagram className={styles.icon} />
        </a>
      )}
      {socialLinks.tiktok && (
        <a
          href={socialLinks.tiktok}
          target="_blank"
          rel="noreferrer"
          aria-label="visit company Tik Tok"
          className={styles.iconLink}
        >
          <FaTiktok className={styles.icon} />
        </a>
      )}
      {socialLinks.facebook && (
        <a
          href={socialLinks.facebook}
          target="_blank"
          rel="noreferrer"
          aria-label="visit company Facebook"
          className={styles.iconLink}
        >
          <FaFacebook className={styles.icon} />
        </a>
      )}
      {socialLinks.twitter && (
        <a
          href={socialLinks.twitter}
          target="_blank"
          rel="noreferrer"
          aria-label="visit company Twitter"
          className={styles.iconLink}
        >
          <FaTwitter className={styles.icon} />
        </a>
      )}
      {socialLinks.youtube && (
        <a
          href={socialLinks.youtube}
          target="_blank"
          rel="noreferrer"
          aria-label="visit company Youtube"
          className={styles.iconLink}
        >
          <FaYoutube className={styles.icon} />
        </a>
      )}
      {socialLinks.linkedIn && (
        <a
          href={socialLinks.linkedIn}
          target="_blank"
          rel="noreferrer"
          aria-label="visit company LinkedIn"
          className={styles.iconLink}
        >
          <FaLinkedin className={styles.icon} />
        </a>
      )}
      {socialLinks.pinterest && (
        <a
          href={socialLinks.pinterest}
          target="_blank"
          rel="noreferrer"
          aria-label="visit company Pinterest"
          className={styles.iconLink}
        >
          <FaPinterest className={styles.icon} />
        </a>
      )}
      {socialLinks.google && (
        <a
          href={socialLinks.google}
          target="_blank"
          rel="noreferrer"
          aria-label="visit company Google Places"
          className={styles.iconLink}
        >
          <FaGoogle className={styles.icon} />
        </a>
      )}
      {socialLinks.yelp && (
        <a
          href={socialLinks.yelp}
          target="_blank"
          rel="noreferrer"
          aria-label="visit company Yelp"
          className={styles.iconLink}
        >
          <FaYelp className={styles.icon} />
        </a>
      )}
    </div>
  );
};

export default FooterSocialIcons;
